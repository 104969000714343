import $ from "jquery";

const $doc = $(document);
const $mobileNav = $(".js-mobile-nav");
const $hamburger = $(".js-hamburger");

function showMobileNav(event) {
  event.preventDefault();
  $mobileNav.toggleClass("is-active");
  $hamburger.toggleClass("is-active");
  if ($mobileNav.hasClass("is-active")) {
    $hamburger.attr("aria-expanded", "true");
  } else {
    $hamburger.attr("aria-expanded", "false");
  }
  // Lock body scroll
  if ($mobileNav.hasClass("is-active")) {
    $("body").css("position", "fixed");
  } else {
    $("body").css("position", "unset");
  }
}

export default function initMobileNav() {
  $doc.on("click", ".js-hamburger", showMobileNav);
  $doc.on("keyup", function (event) {
    if ($mobileNav.hasClass("is-active") && event.key == "Escape") {
      $hamburger.attr("aria-expanded", "false");
      $mobileNav.toggleClass("is-active");
      $hamburger.toggleClass("is-active");
    }
  });
  $doc.on("focusin", function (event) {
    var $target = $(event.target);
    if (!$target.closest(".js-header-wrapper").length) {
      if ($mobileNav.hasClass("is-active")) {
        $hamburger.attr("aria-expanded", "false");
        $mobileNav.toggleClass("is-active");
        $hamburger.toggleClass("is-active");
      }
    }
  });

  // const dropdowns = document.querySelectorAll(
  //   ".js-mobile-nav .menu-item-has-children > .dropdown-toggle",
  // );

  // dropdowns.forEach((dropdown) => {
  //   dropdown.addEventListener("click", function (event) {
  //     event.preventDefault();
  //     const target = event.target;
  //     const parent = target.closest(".menu-item");
  //     parent.classList.toggle("is-active");
  //   });
  // });
}
