const init = () => {
  const mainNav = document.querySelectorAll(
    ".js-header-main-nav .menu-item-has-children",
  );

  mainNav.forEach((item) => {
    const link = item.querySelector(".dropdown-toggle");
    const subMenu = item.querySelector(".header__sub-menu");

    link.addEventListener("click", (e) => {
      e.preventDefault();
      subMenu.classList.toggle("is-open");
    });

    // Close sub-menu when clicking outside or when pressing the Esc keyboard
    function handleClose(e) {
      if (e.type === "keydown" && e.key === "Escape") {
        subMenu.classList.remove("is-open");
      } else if (e.type === "click" && !item.contains(e.target)) {
        subMenu.classList.remove("is-open");
      }
    }
    document.addEventListener("keydown", handleClose);
    document.addEventListener("click", handleClose);
  });


};

export default init;
